import React, { useState, useEffect } from "react";

import './ShowProject.css';

import TextareaAutosize from 'react-textarea-autosize';

import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { SvgClose } from "services/SvgFile";
import { Reg_NewProject } from "services/RegisterData";

export default function PopUP_ShowProject(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));

    const [ title, setTitle ]                 = useState('');
    const [ name, setName ]                   = useState('');
    const [ description, setDescription ]     = useState('');
    const [ logo192, setLogo192 ]             = useState('');
    const [ statusLogo192, setStatusLogo192 ] = useState(false);
    const [ logo512, setLogo512 ]             = useState('');
    const [ statusLogo512, setStatusLogo512 ] = useState(false);
    const [ favicon, setFavicon ]             = useState('');
    const [ statusFavicon, setStatusFavicon ] = useState(false);

    function AddFile(type, value){
        switch (type) {
            case "logo192":
                    if(value){
                        setLogo192(value);
                        setStatusLogo192(true);
                    }else {
                        setLogo192('');
                        setStatusLogo192(false);
                    }
                break;
            case "logo512":
                    if(value){
                        setLogo512(value);
                        setStatusLogo512(true);
                    }else {
                        setLogo512('');
                        setStatusLogo512(false);
                    }
                break;
            case "favicon":
                    if(value){
                        setFavicon(value);
                        setStatusFavicon(true);
                    }else {
                        setFavicon('');
                        setStatusFavicon(false);
                    }
                break;
        }
    }

    function ClosePopUp(){
        setTitle('');
        setName('');
        setDescription('');
        setLogo192('');
        setStatusLogo192(false);
        setLogo512('');
        setStatusLogo512(false);
        setFavicon('');
        setStatusFavicon(false);

        SetModalState('ShowProject', false);
    }

    function SaveData(event){
        event.preventDefault();
        if(title == "" || name == "" || description == "" || logo192 == "" || logo512 == "" || favicon == ""){
            SetModalData('ReturnResponse', { "page": "erro", "text": "Existem campos obrigatórios o preenchimento!" });
            SetModalState('ReturnResponse', true);
        }else {
            props.setLoading(true);
            Reg_NewProject(userId, title, name, description, logo192, logo512, favicon, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);

        SetModalState('ShowProject', false);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterModalData('ShowProject', setModaldata);
        RegisterModalObserver('ShowProject', setShowPopUp);
    }, []);

    return (
        (showPopUp ?
            <form className="PopUp" onSubmit={ SaveData }>
                <div className="all show_project">
                    <div className="div_data type_div">
                        <div className="title">
                            Create new project
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <SvgClose color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            {
                                modalData.qtd == 2 ? 
                                <>
                                    <div className="">
                                        Unfortunately, your profile has reached the limit of projects that can be created.
                                    </div>
                                    <div className="">
                                        Below are the projects you created.
                                    </div>
                                    <div className="list_project">
                                        {
                                            modalData.project.map((elem, index)=>{
                                                return(
                                                    <div className="show_project" key={ index }>
                                                        <div className="title">{ elem.name }</div>
                                                        <div className="div_img">
                                                            <img alt={ elem.title } src={ "./assets/logotipo/" + elem.logo_192 } className="img" />
                                                        </div>
                                                        <div className="div_btn">
                                                            <div className="register">
                                                                Created on date { elem.register_date }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </> : 
                                <>
                                    <div className="list_input_data">
                                        <span className="div_input div_add_img">
                                            <div className={ statusLogo192 == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { statusLogo192 == true ? "Logo 192 added*" : "Add logo 192*" }
                                                    <input type="file" onChange={ (e)=>{ AddFile('logo192', e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                        </span>

                                        <span className="div_input div_add_img">
                                            <div className={ statusLogo512 == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { statusLogo512 == true ? "Logo 512 added*" : "Add logo 512*" }
                                                    <input type="file" onChange={ (e)=>{ AddFile('logo512', e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                        </span>

                                        <span className="div_input div_add_img">
                                            <div className={ statusFavicon == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { statusFavicon == true ? "Favicon added*" : "Add favicon*" }
                                                    <input type="file" onChange={ (e)=>{ AddFile('favicon', e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                        </span>
                                    </div>

                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="space_width" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                                            <span className="name_input">Title page*</span>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="text" className="space_width" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                                            <span className="name_input">Project name*</span>
                                        </div>
                                    </div>

                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <TextareaAutosize className="text_area" onChange={ (e)=>{ setDescription(e.target.value) } } value={ description.replace(/<br>/g,'\n') } required />
                                            <span className="name_input">Descrição*</span>
                                        </div>
                                    </div>

                                    <button className="register_data">
                                        Cadastrar
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
