import { useState, useEffect } from "react";

import './Project.css';

import { SvgSave } from "services/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import PopUP_ShowProject from "components/PopUp/ShowProject";

export default function Page_Project(props){

    const [ userId, setUserId ]   = useState(GetUserData('id'));
    const [ page, setPage ]       = useState(GetUserData('page'));
    const [ nivel, setNivel ]     = useState(GetUserData('nivel'));
    const [ project, setProject ] = useState(GetDataPage('project'));

    function NewProjet(){
        SetModalData('ShowProject', { "qtd": project.filter(item => item.register_id == userId).length, "project": project.filter(item => item.register_id == userId) });
        SetModalState('ShowProject', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterUserData('page', setPage);
        RegisterUserData('nivel', setNivel);
        RegisterDataPage('project', setProject);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterUserPage('page', setPage);
            UnRegisterUserPage('nivel', setNivel);
            UnRegisterDataPage('project', setProject);
        };
    }, []);

    useEffect(()=>{
        setProject(GetDataPage('project'));
    }, [project]);
    
    return(
        <div className="Page_UsersDash_Details">
            <div className="new_block">
                <div className="highlight_title_page">List of projects</div>
                <div className="list_opt_alt_page">
                    <div className="div_add_new_data" onClick={ ()=>{ NewProjet() } }>
                        <div className="new_data_add">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="name_btn_add">New project</div>
                    </div>
                </div>
            </div>

            <div className="list_project">
                {
                    project.map((elem, index)=>{
                        // let dataProject = project.find(item => item.id == elem.id_project);
                        return(
                            <div className="show_project" key={ index }>
                                <div className="title">{ elem.name }</div>
                                <div className="div_img">
                                    <img alt={ elem.title } src={ "./assets/logotipo/" + elem.logo_192 } className="img" />
                                </div>
                                <div className="">
                                    <div className="btn">Access</div>
                                    {/* 
                                    <div className={ elem.type == "hide_page" ? "btn project_hide" : "btn" }>
                                        {
                                            elem.type == "edit" ? "Full access" : 
                                                elem.type == "hide_page" ? 
                                                    "No access" : "Just view"
                                        }
                                    </div> 
                                    */}
                                    <div className="register">
                                        Created by { elem.register_name.split(' ')[0] } on date { elem.register_date }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <PopUP_ShowProject setLoading={ props.setLoading } />
        </div>
    )
}