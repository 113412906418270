import React, { useState, useEffect } from "react";

import './ReturnResponse.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { SvgChekedReturn, SvgErro } from "services/SvgFile";

export default function PopUP_ReturnResponse(){

    const [ modalData, setModaldata ]     = useState({});
    const [ showPopUp, setShowPopUp ]     = useState(false);

    function ClosePopUp(){
        SetModalState('ReturnResponse', false);
    }

    function TypeData(){
        switch (modalData.page) {
            case 'erro':
                return(
                    <>
                        <SvgErro color="#F00000" className="icons_return" />
                        <div className="title_return">
                            { modalData.text == "" ? "Erro ao registrar as informações, tente novamente!" : modalData.text }
                        </div>
                    </>
                )

            default:
                return(
                    <>
                        <SvgChekedReturn color="#43a047" className="icons_return" />
                        <div className="title_return">
                            Informações registradas com sucesso.
                        </div>
                    </>
                )
        }
    }

    useEffect(()=>{
        RegisterModalData('ReturnResponse', setModaldata);
        RegisterModalObserver('ReturnResponse', setShowPopUp);
    }, []);

    return(
        (showPopUp ?
            <>
                <div className="PopUp PopUpCenter">
                    <div className="all return_response">
                        <div className="div_data">
                            <div className="content">
                                {
                                    TypeData()
                                }
                                <div className="close_return" onClick={ ()=>{ ClosePopUp() } } style={ { background: "#2D3C53" } }>
                                    Fechar
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    )
};
