import { useState, useEffect } from "react";

import { GetListPag, RegisterListPag } from "interface/Page";

import Login from "templates/Login";
import Recover from "templates/Recover";
import Projects from "templates/Projects";

export default function App(props) {
    const [ loading, setLoading ]   = useState(false); 
    const [ typePage, setTypePage ] = useState(GetListPag('page'));
    const [ typeDash, setTypeDash ] = useState(GetListPag('typeDash'));

    const [ url, setUrl ] = useState(window.location.href.split("/"));

    function CheckedPage(){
        if(url[3]){
            let segund = url[3].split('=');  
            if(segund[0]){
                return <Recover setLoading={ setLoading } code={ segund[1] } setUrl={ setUrl } />;     
            }else {
                return <Login setLoading={ setLoading } loadingDataPage={ props.loadingDataPage } />;
            }
        }else {
            switch(typePage){
                case "dashboard":
                    return <Projects setLoading={ setLoading } />;
                default:   
                    return <Login setLoading={ setLoading } loadingDataPage={ props.loadingDataPage } />;
            }
        }
    }

    useEffect(()=>{
        RegisterListPag('page', setTypePage);
        RegisterListPag('typeDash', setTypeDash);
    }, []);

    return(
        <>
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="dot-spinner">
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                </div>
            </div>
            {
                CheckedPage()
            }
        </>
    );
}