import { useState, useEffect } from "react";

import './Projects.css';

import Cookies from "universal-cookie";

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePageId, typeCookiePassw } from "fixedData";

import { SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { SvgDashboardExit, SvgLogoTipo, SvgLogoTipo_1, SvgMenuHamburguer, SvgMenuPoint, SvgProject, SvgUser, SvgUserDash } from "services/SvgFile";

import PopUP_ShowFile from "components/PopUp/ShowFile";
import PopUP_EditProfile from "components/PopUp/EditProfile";
import PopUP_Confirmation from "components/PopUp/Confirmation";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import Page_UsersDash from "page/UsersDash";
import Page_UsersDash_Details from "page/UsersDash/Details";

import Page_Project from "page/Project";

export default function Projects(props){
    const cookies = new Cookies();

    const [ typeDash, setTypeDash ]       = useState(GetListPag('typeDash')); 
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ status, setStatus ]         = useState(false); 

    function CkickPage(value){
        SetListPag('currentPage', value);
        document.getElementById('root').scrollTo({ top: 0, behavior: 'smooth' });

        if(document.body.clientWidth <= 1050){
            setStatus(true);
        }

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1050){
                setStatus(true);
            }
        });
    }

    function Logout(){
        SetListPag('page', 'index');

        window.history.pushState("", "", "/");
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
        cookies.remove(typeCookiePageId, '', '/', cookiesRegister);
    }

    function CurrentPage(){
        switch (currentPage) {
            case "access_dash":
                return <Page_UsersDash setLoading={ props.setLoading } />;
            case "access_dash_details":
                return <Page_UsersDash_Details setLoading={ props.setLoading } />;

            case "project":
                return <Page_Project setLoading={ props.setLoading } />;
        
            default:
                break;
        }
    }

    useEffect(()=>{
        RegisterListPag('typeDash', setTypeDash);
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);

        if(document.body.clientWidth <= 1050){
            setStatus(true);
        }
        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1050){
                setStatus(true);
            }
        });

        return ()=>{
            UnRegisterListPag('typeDash', setTypeDash);
            UnRegisterListPag('currentPage', setCurrentPage);
            
            UnRegisterUserPage('file', setUserFile);
            UnRegisterUserPage('name', setUserName);
            UnRegisterUserPage('access', setUserAccess);
        };
    }, []);

    useEffect(()=>{
        setTypeDash(GetListPag('typeDash'));
        CurrentPage();
    }, [typeDash]);

    return(
        <div className="Projects">
            
            <div className={ status ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">

                    <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                        <div className={ status ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                            <div className="">
                                {
                                    status ?
                                    <SvgLogoTipo_1 color="#ffffff" className="logotipo2" /> :
                                    <SvgLogoTipo color="#ffffff" className="logotipo" />
                                }
                            </div>
                            <div className={ status ? "icon_menu icon_menu_active" : "icon_menu" }>
                                <SvgMenuHamburguer color="#ffffff" className="icons" />
                            </div>
                        </div>                    
                    </div>
                    
                    <div className={ status ? "show_data_top" : "show_data_top show_data_top_close" }>
                        <div className="opt_menu_dash">
                            {
                                userAccess == 1 ?
                                <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                                    <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <SvgUserDash color="#FFC400" className="icons_dash" />
                                        </div>
                                        <div className={ status ? "name_page menu_close" : "name_page" }>Users</div>
                                    </div>
                                </div>
                                : null
                            }

                            <div className={ currentPage == "project" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("project"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgProject color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>List of projects</div>
                                </div>
                            </div>

                            <div className={ status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>To go out</div>
                                </div>
                            </div>
                        </div>
                        <div className="div_profile">
                            <div className={ status ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ SetModalState('Profile', true); } }>
                                <div className="div_img">
                                    {
                                        userFile !='' ?
                                        <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                        <SvgUser color="#FFFFFF" className="show_img_profile" />
                                    }
                                </div>
                                <div className={ status ? "name_user menu_close" : "name_user" }>
                                    { userName.split(' ')[0] }
                                </div>
                                <div className={ status ? "open_profile menu_close" : "open_profile" }>
                                    <SvgMenuPoint color="#ffffff" className="icons" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>

            <PopUP_ShowFile setLoading={ props.setLoading } />
            <PopUP_Confirmation setLoading={ props.setLoading } />
            <PopUP_EditProfile setLoading={ props.setLoading } />

            <PopUP_ReturnResponse setLoading={ props.setLoading } />
        </div>
    )
}